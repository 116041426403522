import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../shared/models';
import { AuthService } from '../../shared/services';

export const AuthGuard = (): boolean => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  const user: User | null = authService.currentUser;

  if (user) {
    return true;
  }

  router.navigate(['login']);
  return false;
};
