import { Route } from '@angular/router';
import { AuthGuard } from './core/guards';
import { LoggedPageComponent } from './shared/components';

export const appRoutes: Route[] = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }, // to define

  {
    path: '',
    component: LoggedPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'projects',
        loadChildren: () =>
          import('./modules/projects/projects.routes').then(
            (m) => m.projectsRoutes,
          ),
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.routes').then((m) => m.loginRoutes),
  },

  { path: '**', redirectTo: '' }, // to define
];
